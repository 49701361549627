<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <basic-table
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="checkPermission(['SUPER_ADMIN'])"
          >新增</el-button
        >
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'address.country'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.country
              }}
            </span>
            <span v-else-if="column.prop === 'address.province'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.province
              }}
            </span>
            <span v-else-if="column.prop === 'address.city'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.city }}
            </span>
            <span v-else-if="column.prop === 'address.region'">
              {{ isEmprty(scope.row.address) ? "" : scope.row.address.region }}
            </span>
            <span v-else-if="column.prop === 'address.street'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.street }}
            </span>
            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">失效</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                checkPermission([
                  'CLIENT_ADMIN',
                  'CLIENT_GROUP_ADMIN',
                  'CLIENT_USER',
                ])
              "
              @click.native.prevent="signClient(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              立即采集
            </el-button>
            <el-button
              v-if="scope.row.canUpdate"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              修改
            </el-button>
            <el-button
              class="text-danger"
              v-if="scope.row.canDelete"
              @click.native.prevent="deleteRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="checkPermission(['SUPER_ADMIN'])"
          type="danger"
          size="small"
          @click="onMultipleDelete"
          :disabled="multipleSelected.length <= 0"
          :loading="deleteLoading"
          >批量删除 {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      :title="isEmprty(currentId) ? '新增数据' : '修改数据'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import Form from "./components/Form";
import {
  GetMerchantsList,
  DeleteMerchantsById,
  MultipleDeleteMerchants,
} from "./api";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import config from "@/config";
import FilterSection from "./components/FilterSection";
import PageHeader from "@/components/PageHeader";
import { ReadLocalColumn } from "@/utils/table";
export default {
  components: {
    BasicTable,
    FilterSection,
    Form,
    PageHeader,
  },
  // mixins: [formSelection],
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      deleteLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {
        createdAt: [],
        lastModifiedAt: [],
        name: "",
        contactName: "",
        phoneNo: "",
      },
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {
        paymentTypeName: "type.name",
      },
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "disabled",
            label: "启/禁状态",
            showColumn: false,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "客户名称",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "typeName",
            label: "类型",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "contactName",
            label: "联系人",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "phoneNo",
            label: "联系人电话",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "address.country",
            label: "国家",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.province",
            label: "省份",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.city",
            label: "城市",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.region",
            label: "地区",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.street",
            label: "街道",
            showColumn: true,
            width: 120,
            fixed: null,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            width: 200,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },

          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 100,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetMerchantsList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "createdAt";
      this.orderDirection = "descending";
      this.onResetFilter();
      this.onRefresh();
    },
    onResetFilter() {
      for (const key in this.tableFilters) {
        if (typeof this.tableFilters[key] === "object") {
          if (this.tableFilters[key] instanceof Array) {
            this.tableFilters[key] = [];
          } else {
            this.tableFilters[key] = null;
          }
        } else {
          this.tableFilters[key] = "";
        }
      }
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.formDialog = true;
    },
    editRow(row) {
      this.currentId = row.id;
      this.formDialog = true;
    },
    deleteRow(row) {
      this.$msgbox({
        title: "删除提示",
        message: `是否确定要删除该条回款？`,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            DeleteMerchantsById(row.id)
              .then(() => {
                this.$message.success("操作成功!");
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            done();
          }
        },
      });
    },
    signClient(row) {
      this.$router.push({
        name: "ClientsForm",
        params: {
          id: row.id,
          type: "MERCHANTS",
        },
      });
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onFormCancel() {
      this.currentId = "";
      this.formDialog = false;
    },
    onFormSuccess() {
      if (!isEmprty(this.currentId)) {
        this.formDialog = false;
      }
      this.currentId = "";
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    onMultipleDelete() {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let ids = [];
      ids = this.multipleSelected.map((item) => item.id);
      let nameString = "";
      nameString = this.multipleSelected
        .map((item) => {
          return `${item.clientName}-${item.paymentTypeName}`;
        })
        .toString();
      const h = this.$createElement;
      let message = h(
        "p",
        { style: "word-break:break-all" },
        `是否确定要删除【${nameString}】？`
      );
      this.$msgbox({
        title: "删除提示",
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.deleteLoading = true;
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            MultipleDeleteMerchants(ids)
              .then(() => {
                this.$message.success("操作成功!");
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                this.deleteLoading = false;
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                this.deleteLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>