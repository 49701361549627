<template>
  <el-form
    :model="form"
    :rules="rules"
    status-icon
    ref="ruleForm"
    label-width="100px"
    class="company-ruleForm"
    :disabled="submitting"
    v-loading="loading"
  >
    <el-form-item label="招商类型" prop="typeId">
      <el-select
        v-model="form.typeId"
        placeholder="请选择"
        clearable
        filterable
        class="base-select"
      >
        <el-option
          v-for="item in merchantsTypesOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="招商名称" prop="name">
      <el-input v-model="form.name" placeholder="请输入" clearable></el-input>
    </el-form-item>
    <el-form-item label="联系人名称" prop="contactName">
      <el-input
        v-model="form.contactName"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="联系人电话" prop="phoneNo">
      <el-input
        v-model="form.phoneNo"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="地区选择" prop="address.country">
      <keep-alive>
        <BasicCascader
          v-model="areaValue"
          @change="handleAreasChange"
        />
      </keep-alive>
    </el-form-item>
    <el-form-item label="街道" prop="address.street">
      <el-input
        v-model.trim="form.address.street"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >关 闭</el-button
      >
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        :loading="submitting"
        @click="submitForm('ruleForm')"
        >立即创建</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('ruleForm')"
        v-if="!isEmprty(id)"
        >立即修改</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PutMerchantsById, PostMerchants, GetMerchantsById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import BasicCascader from "@/components/BasicCascader";
import formSelection from "../mixins/formSelection";
export default {
  components: {
    BasicCascader,
  },
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  mixins:[formSelection],
  data() {
    return {
      loading: false,
      submitting: false,
      currentId: null,
      areaValue: [],
      form: {
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        contactName: "",
        name: "",
        phoneNo: "",
        typeId: null,
      },
      rules: {
        name: [{ required: true, message: "请输入招商名称", trigger: "blur" }],
        phoneNo: [
          {
            validator: this.checkPhoneNumber,
            trigger: "blur",
          },
        ],
        typeId: [
          {
            required: true,
            message: "请选择招商类型",
            trigger: ["blur", "change"],
          },
        ],
        "address.country": [
          {
            required: true,
            message: "请选择区域地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.initFormOptions();
        if (!isEmprty(this.id)) {
          this.currentId = this.id;
          this.getFormData(this.id);
        } else {
          this.currentId = null;
          this.loading = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPhoneNumber(rule, value, callback) {
      // console.log('rule', rule)
      if (value && value !== "") {
        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("电话号码格式不正确"));
        }
      } else {
        return callback();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          this.onPostForm();
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    onPostForm() {
      PostMerchants({
        ...this.form,
      })
        .then(() => {
          this.submitting = false;
          this.onEmitSuccess();
          this.inintForm();
          this.$message.success("创建成功");
        })
        .catch((err) => {
          this.submitting = false;
          ShowApiError("新增失败", err);
        });
    },

    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutMerchantsById(this.id, {
            ...this.form,
          })
            .then(() => {
              this.submitting = false;
              this.onEmitSuccess();
              this.inintForm();
              this.$message.success("修改成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetMerchantsById(id)
        .then((res) => {
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          if (this.form.address.region === "") {
            this.areaValue = [
              this.form.address.country,
              this.form.address.province,
              this.form.address.city,
            ];
          } else {
            this.areaValue = [
              this.form.address.country,
              this.form.address.province,
              this.form.address.city,
              this.form.address.region,
            ];
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onCancel() {
      this.inintForm();
      this.$emit("cancel");
    },
    onEmitSuccess() {
      this.$emit("success");
    },
    inintForm() {
      this.form = {
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        contactName: "",
        name: "",
        phoneNo: "",
        typeId: null,
      };
      this.areaValue = [];
      this.$refs["ruleForm"].resetFields();
    },
    handleAreasChange(val) {
      this.form.address.country = val[0] ? val[0] : "";
      this.form.address.province = val[1] ? val[1] : "";
      this.form.address.city = val[2] ? val[2] : "";
      this.form.address.region = val[3] ? val[3] : "";
    }
  },
};
</script>

<style lang="scss" scoped>
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}
</style>