import { GetMerchantsTypesAll } from "@/views/merchants/merchantsType/api";
import { ShowApiError } from "@/request/error";
export default {
    data() {
        return {
            merchantsTypesOptions: [],
        }
    },
    methods: {
        initFormOptions() {
            GetMerchantsTypesAll()
                .then((res) => {
                    this.merchantsTypesOptions = res.data;
                })
                .catch((err) => {
                    ShowApiError("获取招商类型选单失败", err);
                });
        },
    }
}